import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`v1.1`}</p>
    </blockquote>
    <h2>{`Introduction`}</h2>
    <h3>{`Purpose of the data breach protocol`}</h3>
    <p>{`Data breaches can have serious consequences for organizations and individuals, including financial losses, damage to reputation, and legal liabilities. It is therefore important for CoreMedia to have a clear and comprehensive protocol in place to respond to data breaches in an effective and timely manner.`}</p>
    <p>{`The purpose of this data breach protocol is to provide guidance on how to identify, assess, contain, recover from, and communicate about data breaches that may occur at CoreMedia. It applies to all personal data processed by CoreMedia, regardless of the form or format in which it is processed, and to all data processors and controllers within the organization.`}</p>
    <p>{`This data breach protocol is intended to be a living document, which will be reviewed and updated regularly to ensure that it remains effective and relevant. It should be used in conjunction with CoreMedia's other policies and procedures related to data protection and security.`}</p>
    <h2>{`Definitions`}</h2>
    <h3>{`Data Subject's rights`}</h3>
    <p>{`It is important to be aware of the data subject's rights, as they will necessarily correspond to behaviors intended to satisfy them. `}</p>
    <p>{`Thus, CoreMedia should be aware of them at various times, including when designing data collection systems, preparing organizational measures to comply with the GDPR, processing data, deleting data, etc. `}</p>
    <p>{`It may also be contacted by the data subject to exercise some right, in which case it will need to be aware of them in order to give an appropriate response.`}</p>
    <h4>{`Transparency`}</h4>
    <p>{`Information, communications, and rules for the exercise of data subjects' rights must be based on transparency. `}</p>
    <p>{`All communications must be concise, transparent, understandable, and easily accessible, using clear and simple language. `}</p>
    <p>{`Information is provided in writing.`}</p>
    <h4>{`Information`}</h4>
    <p>{`When personal data is collected from the data subject by CoreMedia, as the Controller, a set of information must be provided, including:`}</p>
    <p>{`A. Identification: Identity and identification of CoreMedia, its internal representative for data processing, and, if applicable, the DPOs;`}</p>
    <p>{`B. Category of data: Information on the categories of data to be processed;`}</p>
    <p>{`C. Treatment: Information on the type of processing to be carried out with the data provided by the data subject;`}</p>
    <p>{`D. Purpose of processing: Objective of the processing to be carried out, including, if applicable, profile definition;`}</p>
    <p>{`E. Legal basis for processing: Information on the legal basis for the processing, i.e. (i) consent, (ii) contractual or legal requirement, (iii) requirement to be part of a contract, (iv) legitimate interest. Also indicate whether the provision of the data is mandatory, as well as the consequences of not providing it;`}</p>
    <p>{`F. Recipients: If there is a transfer or transmission of data, identify the recipients and/or categories of recipients;`}</p>
    <p>{`G. International transfer: If applicable, identify CoreMedia's intention, as the controller, to transmit the data to a third country and/or international organization, and (i) the terms under which it is made, (ii) the applicable legal framework, and (iii) the existing guarantees;`}</p>
    <p>{`H. Retention period: Period for which the data will be kept, or, if this is not possible, the criteria for determining it;`}</p>
    <p>{`I. Automated decision-making processes: Information on the existence of automated decision-making processes, including profiling, and relevant information on the logic of the process and the consequences for the data subject;`}</p>
    <p>{`J. Information on who, in addition to the interlocutor, has access to personal data;`}</p>
    <p>{`K. Data subject rights: inform about the rights to (i) request from CoreMedia access, correction and/or erasure of data or restriction of processing, (ii) portability, (iii) withdraw consent without affecting the lawfulness of processing carried out previously, (iv) complain to the supervisory authority.`}</p>
    <h4>{`Access`}</h4>
    <p>{`The data subject has the right to obtain from CoreMedia, as the controller, confirmation as to whether or not personal data concerning them is being processed, and, where that is the case, access to the personal data and the information referred to previously in this document.`}</p>
    <p>{`Exercise of this right by the data subject shall confer on them the right to receive a copy of the personal data undergoing processing. The controller may charge a reasonable fee based on administrative costs for any additional copies requested by the data subject.`}</p>
    <p>{`If the data subject submits their request electronically, the information must be provided in a commonly used electronic format, unless the data subject requests otherwise.`}</p>
    <h4>{`Rectification`}</h4>
    <p>{`The data subject has the right to obtain from CoreMedia, as the controller, the rectification of inaccurate personal data concerning them without undue delay.`}</p>
    <p>{`Taking into account the purposes of the processing, the data subject has the right to have incomplete personal data completed, including by means of providing a supplementary statement.`}</p>
    <h4>{`Limitation of processing`}</h4>
    <p>{`The data subject has the right to obtain from CoreMedia, as the controller, the limitation of processing in several legally provided cases, including:`}</p>
    <ul>
      <li parentName="ul">{`objecting to the processing or `}</li>
      <li parentName="ul">{`contesting the accuracy of the data.`}</li>
    </ul>
    <h4>{`Objection to Processing`}</h4>
    <p>{`The data subject has the right to object r at any time, on grounds relating to their particular situation, to the processing of their personal data. CoreMedia, as the controller, will cease processing the personal data unless compelling legitimate grounds for the processing which override the interests, rights, and freedoms of the data subject, or for the establishment, exercise, or defense of legal claims are presented.`}</p>
    <h4>{`Objection to Automated Individual Decision-Making`}</h4>
    <p>{`The data subject has the right not to be subject to a decision based solely on automated processing, including profiling, that produces legal effects concerning them or similarly significantly affects them.`}</p>
    <h4>{`Withdrawing Consent`}</h4>
    <p>{`The data subject has the right to withdraw their consent at any time.`}</p>
    <p>{`The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal.`}</p>
    <h4>{`Portability`}</h4>
    <p>{`The data subject has the right to receive the personal data concerning them that they have provided to CoreMedia, as the controller, in a structured, commonly used, and machine-readable format, and have the right to transmit those data to another controller without hindrance from CoreMedia, where the processing is based on consent or is carried out by automated means.`}</p>
    <h4>{`Erasure`}</h4>
    <p>{`The data subject has the right to obtain from the controller the erasure of their personal data without undue delay, and the controller shall erase the personal data, in the cases and with the limitations (cases of non-erasure) provided for in the GDPR.`}</p>
    <h4>{`Complaint`}</h4>
    <p>{`The data subject has the right to file a complaint with the Portuguese Data Protection Authority - CNPD. `}</p>
    <h3>{`Suspicions, Breaches and Mitigation`}</h3>
    <p>{`CoreMedia SA has implemented risk management and impact mitigation mechanisms, which include:`}</p>
    <ul>
      <li parentName="ul">{`discovering, testing and fixing vulnerabilities through penetration testing;`}</li>
      <li parentName="ul">{`preparing, optimizing and improving governance models and incident detection processes through simulated cyber attacks.`}</li>
    </ul>
    <p>{`Annual audits are also carried out on the services and applications in order to identify any vulnerabilities that may compromise the security of the platform.`}</p>
    <p>{`If any vulnerabilities are identified, they are immediately mitigated by the system or application development team.`}</p>
    <p>{`The concept of `}<strong parentName="p">{`"personal data breach"`}</strong>{` refers to a security breach that accidentally or unlawfully causes the destruction, loss, alteration, disclosure and/or unauthorized access to personal data transmitted, stored or subjected to any other type of processing. `}</p>
    <p>{`Incidents can be categorized according to the following three principles of information security:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Security breach`}</strong>{` - unauthorized or accidental access/disclosure of personal data.`}</li>
      <li parentName="ol"><strong parentName="li">{`Availability breach`}</strong>{` - accidental or unauthorized loss of access to personal data or its destruction.`}</li>
      <li parentName="ol"><strong parentName="li">{`Integrity breach`}</strong>{` - unauthorized or accidental alteration of personal data.`}</li>
    </ol>
    <p>{`A breach can potentially have a range of significant adverse effects on individuals, which can result in physical, material and/or non-material damage, including loss of control over personal data, limitation of rights, discrimination, identity theft or fraud, financial loss, unauthorized reversal of pseudonymization, damage to reputation and loss of confidentiality of personal data protected by professional secrecy. It can also mean significant economic or social disadvantages for the data subject.`}</p>
    <p>{`Therefore, the GDPR requires the controller to notify the competent supervisory authority of the breach unless it is unlikely that such adverse effects will occur. If there is a likely risk of such adverse effects occurring, the GDPR requires the controller to notify the affected individuals as soon as reasonably possible.`}</p>
    <h2>{`Security, Technical and Organizational Measures`}</h2>
    <p>{`CoreMedia conducts itself to the highest standards of compliance and promotes a secure operating environment for handling Personal Data on behalf of its Customers.`}</p>
    <h3>{`Storage`}</h3>
    <p>{`All information and/or databases that CoreMedia has, whether its own or its customers', are located and stored in contracted data centers, solely on:`}</p>
    <p>{`A. Mechanical disks`}</p>
    <p>{`B. SSD disks`}</p>
    <p>{`CoreMedia's servers are hosted in data centers certified according to ISO/IEC20000-1:2001 and ISO/IEC27001:2013.`}</p>
    <p>{`Physical access to these systems is only authorized for 6 CoreMedia employees. `}</p>
    <p>{`To physically access the servers, any of the six employees must receive a written internal order, issued by the Internal Data Protection Manager, authorizing the same access.`}</p>
    <p>{`At the data center facilities, access is validated through identification to a security officer at the entrance of the building. After authorization, a card is provided that gives access to the electronic barrier, for access to specific areas of the data center.`}</p>
    <p>{`Access to the backstage is carried out by being accompanied by a data center representative, who has a physical key to open the rack door.`}</p>
    <p>{`As for physical media, any entry and/or exit of equipment from the data centers:`}</p>
    <p>{`A. Requires prior and express authorization from the data center itself`}</p>
    <p>{`B. All movements (whether entries or exits) are properly recorded`}</p>
    <p>{`In the event of an actual exit of any equipment from a data center, such as a disk, it is automatically formatted, thus safeguarding the confidentiality of the information by destroying it.`}</p>
    <p>{`Similarly, upon the entry of any equipment, it is analyzed by the Development team, which ensures and certifies that it is completely empty, without any type of information. `}</p>
    <p>{`As for backup and recovery, given the high occupation capacity of the databases, CoreMedia makes its backups on another system, although in the same data center.`}</p>
    <p>{`These backups are then transmitted via SSH to a remote system, located in another data center, thus minimizing data loss in case of breach or destruction. `}</p>
    <h3>{`Resources`}</h3>
    <h4>{`Human Resources - CoreMedia as an employer (Controller)`}</h4>
    <p>{`As an employer, CoreMedia collects a multitude of information from its employees. It does so to comply with legal obligations, to prepare employment contracts with legally required requirements, to be able to fulfill tax and contribution obligations, to recruit new workers, etc.`}</p>
    <p><strong parentName="p">{`Records, Information and Confidentiality`}</strong></p>
    <p>{`For data protection purposes, all those who have an employment, internship, service provision and/or other relationship with CoreMedia are considered employees.`}</p>
    <p>{`All CoreMedia employees who use personal data are individually responsible for complying with the applicable legal and regulatory provisions, in general terms.`}</p>
    <p>{`All employees:`}</p>
    <p>{`A. underwent a data protection awareness action.`}</p>
    <p>{`B. signed a Consent Statement regarding their personal data collected by CoreMedia and its treatment within the scope of their employment contract and `}</p>
    <p>{`C. received the CoreMedia Personal Data Protection Code of Conduct. `}</p>
    <p>{`Furthermore, according to Article 11 of the CoreMedia Personal Data Protection Code of Conduct, all employees who deal with personal data are required to maintain secrecy about them, namely not to reveal or use them except in cases where the law obliges, that is, when public entities require the transmission of data (police, courts, finance, social security and/or other public entities).`}</p>
    <p>{`In this vein, Article 12 of the same Code of Conduct states that:`}</p>
    <p>{`A. all employees are disciplinarily responsible for the violation or illegal transmission of personal data that CoreMedia has in its databases;`}</p>
    <p>{`B. this responsibility will be assessed through a disciplinary procedure that may result in one of the sanctions provided for in the Labor Code;`}</p>
    <p>{`C. in addition to the sanction that may be applied, CoreMedia may also charge the employee who illegally transmitted the data to assume the fines that the CNPD may impose.`}</p>
    <p>{`All employment contracts stipulate, as regards the duties of employees, that they are required:`}</p>
    <p>{`A. to observe strict secrecy regarding all information obtained in the execution of the employment contract, including communications addressed to them or information disclosed to them, whether related to the employer, its customers, suppliers, banking or other stakeholders; `}</p>
    <p>{`B. to comply with the general prohibition of copying or using for other purposes, other than those that are strictly professional, as well as transmitting or providing to third parties, the databases to which they have access, whether belonging to the employer or to third parties.`}</p>
    <p>{`In addition, all granted contracts contain an RGPD Addendum.`}</p>
    <p>{`CoreMedia also has an internal registry that ensures that the people under its responsibility have committed in writing to maintain the confidentiality of the personal data to which they have access, that they are aware of the rules and procedures that must be adopted in the processing of personal data, and that they are aware of their responsibilities and obligations in relation to personal data protection.`}</p>
    <p>{`Finally, CoreMedia has a list containing, namely:`}</p>
    <p>{`A. identification of the employee;`}</p>
    <p>{`B. team;`}</p>
    <p>{`C. function;`}</p>
    <p>{`D. level of data access security.`}</p>
    <p><strong parentName="p">{`Identification, Authentication and Access Control`}</strong></p>
    <p>{`Identification, Authentication and Access Control`}</p>
    <p>{`All employees are treated as users.`}</p>
    <p>{`Credentials for access to servers comply with a minimum degree of complexity according to best security practices, that is:`}</p>
    <p>{`A. minimum 8 characters;`}</p>
    <p>{`B. uppercase and lowercase;`}</p>
    <p>{`C. numbers;`}</p>
    <p>{`D. special characters;`}</p>
    <p>{`E. cannot be identical to the last 3 keys used;`}</p>
    <p>{`F. cannot be identical to dictionary keys.`}</p>
    <p>{`Furthermore, the security key complexity policy for access to the Backoffice can be parameterized (with higher complexity levels) on the Byside platform according to customer policies.`}</p>
    <p>{`As for access control, our Development team, particularly the Programming subteam, has the lowest privilege security levels, developing their work in a segregated environment and with fictitious data.`}</p>
    <p>{`On the other hand, it is the Operational team, the customer managers, who have access to customer data.`}</p>
    <p>{`They are only enabled to perform the operations requested by the Customers (Controllers), in which case CoreMedia and its employees act as Processors.`}</p>
    <p>{`Furthermore, all accesses to data and operations carried out with them are subject to registration on the CoreMedia platform. Thus, it is possible to know who, when and how accessed and what was done with and to the data accessed.`}</p>
    <p>{`This is one of the mechanisms that allows CoreMedia to evaluate and verify suspicions or even violations.`}</p>
    <h4>{`Material Resources - CoreMedia as a provider (Processor)`}</h4>
    <p>{`Companies like CoreMedia must be especially careful in complying with the GDPR, because compliance with it is constantly monitored by their customers.`}</p>
    <p>{`This is because under the GDPR `}<strong parentName="p">{`"when the data processing is carried out on your behalf, the controller shall only use processors providing sufficient guarantees to implement appropriate technical and organizational measures in such a way that the processing meets the requirements of this Regulation and ensures the protection of the rights of the data subject".`}</strong>{` `}</p>
    <p>{`CoreMedia, as a Subcontractor (Processor), is able to ensure that it has the most stringent security, technical, and organizational measures.`}</p>
    <p>{`It adds: `}<strong parentName="p">{`"Subprocessing is governed by a contract or other legal act under Union or Member State law, which binds the subprocessor to the controller, establishes the subject matter and duration of the processing, the nature and purpose of the processing, the type of personal data and categories of data subjects, and the obligations and rights of the controller".`}</strong></p>
    <p>{`For this reason, all contracts have been reviewed and an GDPR Addendum covering all obligations arising from the Regulation has been attached to them.`}</p>
    <p>{`With regard to the data processing activities carried out by CoreMedia (Processor) on behalf of the client (Controller), all of these have written support, that is, only data processing activities that are requested by the Client via email or direct request in the CoreMedia support tool are carried out. `}</p>
    <p>{`In the case of "subcontracting sub-contractors" (Third Parties), CoreMedia's policy is to strictly comply with the GDPR, so it does not `}<strong parentName="p">{`subcontract another subcontractor`}</strong>{` without the prior written authorization of the controller (Client - Controller).`}</p>
    <p>{`In the event of authorization and, if necessary, CoreMedia informs the controller (Controller) of any intended changes regarding the increase in the number or replacement of other subcontractors, thus giving the controller (Client - Processor) the opportunity to object to such changes.`}</p>
    <p>{`In any case, whenever the client requests it, CoreMedia is able to send them information and documentation related to requests, queries and/or inspections promoted by the CNPD, actions developed and their consequences, as well as Reports resulting from any Independent Periodic Audits, relating to compliance with legal provisions on the protection of personal data, including GDPR.`}</p>
    <h2>{`Procedures`}</h2>
    <h3>{`Suspicion/Violation`}</h3>
    <p>{`If you become aware that a personal data breach has occurred or may have occurred, it is crucial that you report the situation immediately so that the response can be as quick as possible.`}</p>
    <p>{`The GDPR provides that in the event of a personal data breach, the controller (CoreMedia) must notify the `}<strong parentName="p">{`supervisory authority`}</strong>{` - in Portugal, the National Data Protection Commission (CNPD) - as well as, in certain situations, communicate the breach to the `}<strong parentName="p">{`data subjects`}</strong>{` themselves**.`}</p>
    <p>{`Non-compliance with these obligations can result in substantial fines.`}</p>
    <p>{`CoreMedia has created a "crisis team" for data breaches.`}</p>
    <p>{`Its members are: Filipe Nunes, Daniel Ramos, and Rui Campos, with Susana Silva as the Internal Data Protection Officer.`}</p>
    <p>{`All documentation and other supports identify the email address `}<a parentName="p" {...{
        "href": "mailto:protecaodedados@byside.com"
      }}>{`protecaodedados@byside.com`}</a>{` so that clients and/or others can put all questions related to the GDPR and data protection.`}</p>
    <p>{`Nádia Mota Veiga and Diana Devezas are the Data Protection Officers at CoreMedia.`}</p>
    <p>{`Promptly detecting and quickly dealing with a breach can minimize the damage caused.`}</p>
    <h4>{`Requests for data processing by CoreMedia from the Client (Controller) or made independently`}</h4>
    <p>{`In the case of any employee, if the request made by the Client (Controller) violates the GDPR rules or any other data protection provisions, they must immediately inform the DP Responsible of their team who will immediately inform the Client (Controller) of the suspected violation of the Regulation.`}</p>
    <p>{`However, regarding requests made directly on the platform by the clients themselves (Controllers), independently, they are not verified by employees, so the responsibility for compliance and guarantee of the GDPR rules lies with the client (Controller) themselves.`}</p>
    <p>{`However, if for any reason, an employee verifies this type of situation, they must immediately report it to the DP Responsible of their team who will proceed as they see fit.`}</p>
    <h4>{`Procedure in case of Suspected Violations`}</h4>
    <p>{`In the event of a data breach, it is essential to determine what happened, the nature and cause of the breach, the extent of the damage and the consequences of the breach.`}</p>
    <p>{`To do this, a `}<strong parentName="p">{`Suspected Breach Report`}</strong>{` must be completed immediately and submitted to the Data Protection (DP) responsible of your team, who will proceed as they see fit, including forwarding the report to the Development team for technical analysis of the nature, cause, and data/services in crisis.`}</p>
    <p>{`Even if, after analysis by the Development team, it is not confirmed that a data breach has occurred, the DP Responsible of the team must decide whether to inform the CNPD and/or the data subjects. `}</p>
    <h4>{`Procedure in case of Personal Data Breach`}</h4>
    <p>{`In the event of suspicion and confirmation of a breach, it is necessary to immediately initiate all procedures to prevent or minimize the damage caused by the breach and only then proceed with the recovery, correction or deletion of data, if necessary.`}</p>
    <p>{`All of these procedures must be properly outlined and documented in the Breach Report.`}</p>
    <p>{`Note that everything must be mapped, as the `}<strong parentName="p">{`Breach Report`}</strong>{` must be drawn up within the first 48 hours after becoming aware of the breach. `}</p>
    <p>{`In any case, it is essential to preserve all evidence of the breach.`}</p>
    <p>{`Not only to better correct and protect the mechanisms, but also in order to establish responsibilities (whether disciplinary, contractual or even criminal).`}</p>
    <p>{`Hence the need to prepare as detailed reports as possible.`}</p>
    <p>{`Finally, note that being as clear and specific as possible in terms of the cause and nature of the breach, as well as the mapping of the data and/or services violated, is an essential task for the assessment and determination of the entities that must be notified.`}</p>
    <h2>{`Responsibility and notifications`}</h2>
    <p>{`The chain of responsibility in case of violation may be different depending on the nature and cause of the violation and the extent of the damages caused.`}</p>
    <p>{`The CoreMedia reporting chain is:`}</p>
    <p>{`Depending on the violation, it may be necessary or not to notify various entities that may be:`}</p>
    <p>{`A. Notification to the DPO, in case of personal data holders being involved:`}</p>
    <ul>
      <li parentName="ul">{`Damages to personal data subjects;`}</li>
      <li parentName="ul">{`Violation of large volume personal data and/or`}</li>
      <li parentName="ul">{`Violation of any sensitive data.`}</li>
    </ul>
    <p>{`B. CNPD - National Data Protection Commission;`}</p>
    <p>{`C. Personal data subjects;`}</p>
    <p>{`D. The Data Controller, when CoreMedia is contractually in the position of Processor;`}</p>
    <p>{`E. Police authorities, in the case of a violation that constitutes a crime.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      